import * as React from "react";
import { useTranslation } from "react-i18next";
import { Container, Grid } from "@mui/material";
import { constants } from "../../config/appConfig";

import "./AnalysisDownloaded.scss";
import PdfDownloadedIcon from "../../assets/images/pdf-downloaded.png";

export default function AnalysisDownloaded() {
  const { t } = useTranslation('AnalysisDownloaded');

  return (
    <div className="analysis-downloaded-wrapper">
      <div className="analysis-downloaded-content text-center circle-straight-background">
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={12} md={6}>
            <Container>
              <img src={PdfDownloadedIcon} alt="Download icon" className="img-fluid download-icon"/>
              <h1>{t('analysis-downloaded-title')}</h1>
              <p className="font-16">
                <span>{t('downloaded-report-line1')}</span>
                <br/>
                <span>{t('downloaded-report-line2')}</span>
                <br/>
              </p>
            </Container>
          </Grid>
        </Grid>
      </div>

      <div className="improve-posture-wrapper text-center">
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={12} md={6}>
            <Container>
              <h2>{t('tips-title')}</h2>
              <a className="pc-theme-button"
                 onClick={() => window.open(t('learn-about-posture-url'))}>
                {t('learn-more-posture-btn')}
              </a>
            </Container>
          </Grid>
        </Grid>
      </div>

      <div className="healthy-workout-wrapper text-center">
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={9} md={9} lg={6} xl={6}>
            <Container>
              <h2>{t('come-back-title')}</h2>
              <h3>{t('come-back-desc1')}</h3>
              <h3>{t('come-back-desc2')}</h3>

              <a className="pc-theme-button orange"
                 onClick={() => window.open(constants.HEALTHY_WORKOUT_URL)}>
                {t('visit-workout-button')}
              </a>
            </Container>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
