const MEDIUM_SEVERITY = 75;
const LOW_SEVERITY = 25;
/**
 * Get slider class name by severity
 * @param {number} value
 * @returns {string}
 */
export const getSliderClassName = (value: number): string => {
    let sliderClassName = 'sliderRedColor';

    if (value <= MEDIUM_SEVERITY) {
        sliderClassName = 'sliderYellowColor';
    }
    if (value <= LOW_SEVERITY) {
        sliderClassName = 'sliderBlueColor';
    }

    return sliderClassName;
}