export type TBiotonixAnalysis = {
  deviations: {
    tag: string,
    severity: number
  }[],
  error: number,
  frontLandmarks: {
    tag: string,
    x: number,
    y: number
  }[],
  message: string,
  score: number,
  sideLandmarks: {
    tag: string,
    x: number,
    y: number
  }[]
}

export const DefaultBiotonixAnalysis: TBiotonixAnalysis = {
  deviations: [{
    tag: '',
    severity: 0
  }],
  error: 0,
  frontLandmarks: [{
    tag: '',
    x: 0,
    y: 0
  }],
  message: '',
  score: 0,
  sideLandmarks: [{
    tag: '',
    x: 0,
    y: 0
  }]
}