import type { TJebbitData } from "./Jebbit.type";

export interface IUserData extends TJebbitData {
  trackId?: string;
  frontPhoto?: string;
  sidePhoto?: string;
}

export const DefaultUserData: IUserData = {
  trackId: '',
  frontPhoto: '',
  sidePhoto: ''
}