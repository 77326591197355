import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { APP_LNG } from "./config/appConfig";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: APP_LNG,
    fallbackLng: 'en',
    preload: [APP_LNG]
  }).then();
