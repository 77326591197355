import { getMarketRegion } from "../services/getMarketRegion";

const DATE = new Date();

/**
 * DEVTEST constants
 */
export const FAKES = {
  X_FUNCTIONS_KEY: "1234",
  GET_ANALYSIS_URL: "https://posture-coach/api/get-analysis",
  CHECK_REPORT_URL: "https://posture-coach/api/check-report",
  EMAIL_REPORT_URL: "https://posture-coach/api/email-report",
  UPLOAD_DATA_URL: "https://posture-coach/api/upload-data",
}
export const X_FUNCTIONS_KEY = process.env.REACT_APP_X_FUNCTIONS_KEY || FAKES.X_FUNCTIONS_KEY;
/**
 * Routing behavior constants
 */
export const HiddenHeaderPages: string[] = ['/loadingscreen', '/turnsideways', '/analyzingresults', '/networkerror', '/notfound'];
export const HiddenBackButtonPages: string[] = ['/postureanalysis', '/scaninstructions'];
export const BackButtonRedirects: { [id: string]: string } = {'/scaninstructions': '/'};
/**
 * Main app constants
 */
export const constants: { [name: string]: string } = {
  DEFAULT_MARKET_REGION: "EN",
  DEFAULT_ENV_PREFIX: "PROD",
  GA_TAG_ID_GLOBAL: "UA-72584985-79",
  GA_TAG_ID_GERMAN: "UA-54279487-20",
  HEALTHY_WORKOUT_URL: "https://voltaren-com-master.preprod-cf65.ch.adobecqms.net/health-wellness-pain/exercise-diet/exercises-to-do-at-home/",
  LEARN_ABOUT_POSTURE_URL: "https://voltaren-com-master.preprod-cf65.ch.adobecqms.net/health-wellness-pain/understanding-pain/good-posture-back-pain/",
  TERMS_OF_USE_URL: "https://www.terms.haleon.com/en-gb/",
  PUBLIC_HEALTH_REPORT_URL: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC1936962/#B41",
  BACK_HEALTH_ARTICLE_URL: "https://my.clevelandclinic.org/health/articles/4485-back-health-and-posture",
  EN_PDFReportFilename: `EN-Voltaren Posture Coach-${DATE.toLocaleDateString('EN')}-${Date.now()}.pdf`,
  DE_PDFReportFilename: `Voltaren Haltungscheck-${DATE.toLocaleDateString('DE')}-${Date.now()}.pdf`,
};

export const APP_LNG = await getMarketRegion();
export const PDFReportFilename = APP_LNG === 'DE' ? constants.DE_PDFReportFilename : constants.EN_PDFReportFilename;