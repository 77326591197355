import React from 'react';
import { useTranslation } from 'react-i18next';
import { getSliderClassName } from '../../../helpers/getSliderClassName';
import { Slider } from '@mui/material';

type AnalysisDetailsComponentProps = {
  key: string;
  title: string;
  value: number;
  icon: string;
}

export const AnalysisDetailsComponent = (props: AnalysisDetailsComponentProps) => {
  const { t } = useTranslation('PostureAnalysis');

  return (
    <div className="analysis-details-card">
      <div className="analysis-details-text">
        <h5>{t(props.title)}</h5>
        <div>
          <p>{props.value} % {t('deviation')}</p>
          <div className={getSliderClassName(props.value)}>
            <Slider disabled defaultValue={props.value} aria-label="Disabled slider"/>
          </div>
        </div>
      </div>

      <div className="analysis-details-image">
        <img src={props.icon} alt="Voltaren Posture Coach Deviation result" className="img-fluid"/>
      </div>
    </div>
  );
}
