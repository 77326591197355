import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { UserDataState } from "../../states/recoilStates";
import { EmailButton } from "./components/EmailButton";
import { Grid, Container } from '@mui/material';

import './FocusAreas.scss';
import NoFocusAreasImage from '../../assets/images/no-focus-areas.png'

export default function NoFocusAreas() {
  const { t } = useTranslation('FocusAreas');
  const { email, trackId } = useRecoilValue(UserDataState);

  return (
    <div className="focus-areas-wrapper">
      <div className="focus-areas-description">
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={9} md={9} lg={6} xl={6}>
            <Container>
              <h1>{t('no-focus-title')}</h1>
            </Container>
          </Grid>
        </Grid>
      </div>

      <div className="no-focus-areas-image-wrapper">
        <Grid item xs={12} sm={9} md={9} lg={6} xl={6} display="flex" justifyContent="center" overflow="hidden">
          <img src={NoFocusAreasImage} alt="No focus areas body"/>
        </Grid>
      </div>

      <Grid container direction="row" justifyContent="center" alignItems="center" textAlign="center">
        <Grid item xs={12} sm={9} md={9} lg={6} xl={6}>
          <Container>
            <p>{t('no-focus-description')}</p>
          </Container>
        </Grid>
      </Grid>

      <div className="focus-areas-buttons text-center">
        <Container>
          <Link to={t('movement-quiz-link')} className="link-white quiz-button pc-theme-button orange" target="_blank">
            {t('movement-quiz-btn')}
          </Link>

          {trackId && email && <EmailButton trackId={trackId} email={email}/>}
        </Container>
      </div>
    </div>
  );
}
