import * as React from 'react';
import Areas from './Areas/Areas';
import { useTranslation } from "react-i18next";
import { useRecoilValue } from 'recoil';
import { EmailButton } from "./components/EmailButton";
import { UserDataState } from '../../states/recoilStates';
import { Container, Grid } from '@mui/material';
import { DownloadReportButton } from "./components/DownloadRepotButton";

import './FocusAreas.scss';

const ALLOW_REPORT_DOWNLOAD = true;

export default function FocusAreas() {
  const { t } = useTranslation('FocusAreas');
  const { email, trackId } = useRecoilValue(UserDataState);

  return (
    <div className="focus-areas-wrapper">
      <div className='focus-areas-description'>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={9} md={9} lg={6} xl={6}>
            <Container>
              <h1>{t('title')}</h1>
              <p className="details">{t('description')}</p>
            </Container>
          </Grid>
        </Grid>
      </div>

      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={9} md={9} lg={6} xl={6}>
          <Container>
            <Areas/>
          </Container>
        </Grid>
      </Grid>

      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={9} md={9} lg={6} xl={6}>
          <Container>
            <p>{t('use-note-1')}</p>
            <p>{t('use-note-2')}</p>
          </Container>
        </Grid>
      </Grid>

      <div className="focus-areas-buttons text-center">
        <Container>
          <div className="bottom-stick-button-wrapper">
            {trackId && ALLOW_REPORT_DOWNLOAD && <DownloadReportButton trackId={trackId}/>}
            {trackId && email && <EmailButton trackId={trackId} email={email}/>}
          </div>
        </Container>
      </div>
    </div>
  );
}
