import "./HomePage.scss";

import React from "react";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Grid } from "@mui/material";
import { constants } from "../../config/appConfig";
import { Footer } from "../../components/Footer/Footer";

export default function HomePage() {
  const { t } = useTranslation('HomePage');
  const instructionsText = [
    { title: t('counter-title-1'), description: t('counter-desc-1') },
    { title: t('counter-title-2'), description: t('counter-desc-2') },
    { title: t('counter-title-3'), description: t('counter-desc-3') }
  ];

  return (
    <div className="homepage-wrapper">
      <div className="homepage-banner-content">
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={10} md={8} lg={6} xl={6}>
            <Container>
              <h1>{t('main-title')}</h1>
              <p className="font-16 text-center">
                {t('introduction')}
                <span className="highlighted-text">{t('highlighted-introduction')}</span>
              </p>
            </Container>
          </Grid>
        </Grid>
      </div>

      <div className="homepage-image-container"></div>

      <div className="gain-insights-wrapper">
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={9} md={9} lg={6} xl={6}>
            <Container>
              <h2 className="text-center insights-title">{t('insights-title')}</h2>
            </Container>
          </Grid>
        </Grid>
      </div>

      <div className="how-it-works-wrapper">
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={10} md={8} lg={6} xl={6}>
            <Container>
              <h2>{t('how-it-works')}</h2>
              <ol className="instructions-list">
                {instructionsText.map(li => <li key={uuidv4()}>
                  <div className="list-group">
                    <div className='instructions-heading'>{li.title}</div>
                    <p>{li.description}</p>
                  </div>
                </li>)}
              </ol>

              <p className="free-tool-info">
                {t('free-tool-info')}
                &nbsp;
                <a href={constants.TERMS_OF_USE_URL} rel="noopener noreferrer"
                   target="_blank">{t('terms-of-use')}</a>
              </p>
            </Container>
          </Grid>
        </Grid>
      </div>

      <div className="posture-pain-wrapper">
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={10} md={8} lg={6} xl={6}>
            <Container>
              <h2 className="posture-pain-title">{t('posture-pain-title')}</h2>
              <p>{t('posture-pain-desc-1')}</p>
              <p>{t('posture-pain-desc-2')}</p>
              <p>{t('posture-pain-desc-3')}</p>
            </Container>
          </Grid>
        </Grid>
      </div>

      <div className="homepage-footnote-wrapper">
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={10} md={8} lg={6} xl={6}>
            <Container>
              <p>
                <span>* 'Computer use and habitual spine posture in Australian Adolescents' Leon M Sgtraker, Peter B O'Sullivan, Anne Smith, and Mark Perry. Public Health Rep, 2007 September; </span>
                <a href={constants.PUBLIC_HEALTH_REPORT_URL} rel="noopener noreferrer" target="_blank">
                  https://www.ncbi.nlm.nih.gov/pmc/articles/PMC1936962/#B41
                </a>
                <br/>
                <span>Accessed 12.09.2023</span>
                <br/>
                <span>**“Back Health and Posture” Cleveland Clinic.</span>
                <a href={constants.BACK_HEALTH_ARTICLE_URL} rel="noopener noreferrer" target="_blank">
                  https://my.clevelandclinic.org/health/articles/4485-back-health-and-posture
                </a>
                <br/>
                <span>Accessed 12.09.2023</span>
              </p>
            </Container>
          </Grid>
        </Grid>
      </div>

      <Footer/>

      <div className="text-center bottom-stick-button-wrapper">
        <Link to={'/Jebbit'} className={`link-white pc-theme-button white-border`}>
          {t('start-now-button')}
        </Link>
      </div>
    </div>
  );
}
