import React from 'react';
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilValue } from "recoil";
import { SelfieState } from "../../../states/recoilStates";
import { sleep } from "../../../helpers/common";

import './TurnSideways.scss';

export default function TurnSideways() {
  const navigate = useNavigate();
  const { t } = useTranslation('CameraCapture');
  const selfie = useRecoilValue(SelfieState);

  React.useEffect(() => {
    if (selfie) {
      sleep(2000).then(() => navigate('/CameraCapture'));
    }
  }, [selfie])

  return (
    <div className="turn-sideways-wrapper">
      <div className="turn-sideways-content">
        <h1>{t('turn-left-title')}<br/>{t('silhouette-title')}</h1>

        {!selfie && <LinkButton text={t('continue-button')}/>}
      </div>
    </div>
  );
}

const LinkButton = (props: { text: string }) =>
  <div className="text-center bottom-stick-button-wrapper">
    <Link to="/CameraCapture" className="link-white pc-theme-button">{props.text}</Link>
  </div>;