import promiseRetry from 'promise-retry';
import axios from 'axios';
import { FAKES, X_FUNCTIONS_KEY } from "../config/appConfig";

const CHECK_REPORT_URL = process.env.REACT_APP_CHECK_REPORT_URL || FAKES.CHECK_REPORT_URL;
const RETRIES = 3;
/**
 * Check backend for PDF report
 * @param trackId
 * @constructor
 */
export const checkReport = async (trackId: string) => {
  const instance = axios.create({
    validateStatus: (status) => status == 200
  });

  return await promiseRetry(retry => {
    return instance
      .post(CHECK_REPORT_URL,
        { trackId: trackId },
        { headers: { 'x-functions-key': X_FUNCTIONS_KEY } })
      .catch((err) => {
        if (err.response.status == 204) {
          console.info('Waiting for report ...');
          retry(err);
        }

        throw err;
      });
  }, {
    retries: RETRIES
  });
}
