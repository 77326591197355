export const GTM_TAG = "GTM-W3M3695";

export const tagManagerArgs = {
  gtmId: GTM_TAG,
  dataLayerName: 'dataLayer'
}

type GTMEventType = {
  [id: string]: {
    category: string;
    action: string;
    label?: string;
  }
}

export const GTMEvent = {
  'undefined': {
    category: 'PostureCoach',
    action: 'error: undefined',
    label: window.location.href
  },
  '/': {
    category: 'PostureCoach',
    action: '01 Start Page'
  },
  // The Jebbit event is managed by the Jebbit team
  // '/jebbit': {
  //   category: 'PostureCoach',
  //   action: '02 Jebbit',
  // },
  '/scaninstructions': {
    category: 'PostureCoach',
    action: '05 Scan Instructions'
  },
  '/cameracapture': {
    category: 'PostureCoach',
    action: '06 Camera Capture',
  },
  '/turnsideways': {
    category: 'PostureCoach',
    action: '07 Turn Sideways'
  },
  '/analyzingresults': {
    category: 'PostureCoach',
    action: '08 Analyzing Results'
  },
  '/postureanalysis': {
    category: 'PostureCoach',
    action: '09.1 Posture Analysis',
  },
  '/focusareas': {
    category: 'PostureCoach',
    action: '09.2 Focus Areas'
  },
  '/nofocusareas': {
    category: 'PostureCoach',
    action: '09.3 No Focus Areas'
  },
  '/analysissent': {
    category: 'PostureCoach',
    action: '11 Analysis Sent By Email'
  },
  '/analysisdownloaded': {
    category: 'PostureCoach',
    action: '10 Analysis Downloaded'
  },
  '/networkerror': {
    category: 'PostureCoach',
    action: 'Error: Network Error',
    label: window.location.href
  },
  '/notfound': {
    category: 'PostureCoach',
    action: 'Error: Not Found',
    label: window.location.href
  }
} as GTMEventType;
