import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { emailReport } from "../../../services/emailReport";
import { useTranslation } from "react-i18next";

export const EmailButton = (props: { trackId: string, email: string }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('FocusAreas')
  const SendEmail = () => {
    emailReport(props.trackId, props.email)
      .then(() => navigate('/AnalysisSent'))
      .catch(() => navigate('/NetworkError'));
  }

  return (
    <Link to="" className="link-white pc-theme-button" onClick={SendEmail}>
      {t('email-my-results')}
    </Link>
  );
}