import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { APP_LNG } from "../../config/appConfig";

import './ErrorPopup.scss';
import * as Icons from './icons';

type ErrorPopupProps = {
  icon: string;
  title: string;
  message: string;
  background: string
}
/**
 * @param props {Icons.Warn|Icons.Rescan|Icons.Blur}
 * @constructor
 */
export const ErrorPopup = (props: ErrorPopupProps) => {
  const { t } = useTranslation('ErrorPopup');

  return <div className={`error-popup-wrapper ${props.background}`}>
    <div className='error-popup-content text-center'>
      <img src={props.icon} alt="Rescan image"/>
      <h2 className='popup-title'>{t('error-popup-title')}</h2>
      <p className='popup-description'>{t('error-popup-desc-head')}<br/>{t('error-popup-desc-desc-start')}</p>
      <ul className='popup-list'>
        <li>{t('error-popup-desc-li-1')}</li>
        <li>{t('error-popup-desc-li-2')}</li>
        <li>{t('error-popup-desc-li-3')}</li>
        <li>{t('error-popup-desc-li-4')}</li>
        <li>{t('error-popup-desc-li-5')}</li>
        {APP_LNG === 'DE' && <li>{t('error-popup-desc-li-6')}</li>}
      </ul>
      <p className='popup-description'>{t('error-popup-desc-desc-end')}</p>

      <Link to={"/ScanInstructions"} className="link-white pc-theme-button orange">
        {t('error-popup-button-title')}
      </Link>
    </div>
  </div>;
}

ErrorPopup.defaultProps = {
  icon: Icons.Warn,
  title: '',
  message: '',
  background: ''
}
