import { Icons } from '../../../assets/images/AnalysisDetails';

interface IDictionary<TValue> {
  [id: string]: TValue;
}

type TDeviationDictionary = {
  [id: string]: {
    group: string,
    code: number,
    title: string,
    icon: string,
    inflammationIcon: string
  }
}

export const deviationDictionary: TDeviationDictionary = {
  RightHeadLateralFlexion: {
    group: "head-tilted",
    code: 1,
    title: "head-tilted-right",
    icon: Icons.HeadTilt,
    inflammationIcon: Icons.HeadTiltOn
  },
  LeftHeadLateralFlexion: {
    group: "head-tilted",
    code: 1,
    title: "head-tilted-left",
    icon: Icons.HeadTilt,
    inflammationIcon: Icons.HeadTiltOn
  },
  ForwardHeadProtrusion: {
    group: "head-is-forward",
    code: 2,
    title: "head-is-forward",
    icon: Icons.HeadIsForward,
    inflammationIcon: Icons.HeadIsForwardOn
  },
  RightShoulderElevation: {
    group: "elevate-shoulder",
    code: 3,
    title: "elevate-right-shoulder",
    icon: Icons.ElevatedShoulderRight,
    inflammationIcon: Icons.ElevatedShoulderOnRight
  },
  LeftShoulderElevation: {
    group: "elevate-shoulder",
    code: 3,
    title: "elevate-left-shoulder",
    icon: Icons.ElevatedShoulder,
    inflammationIcon: Icons.ElevatedShoulderOn
  },
  AnteriorShoulderDeviation: {
    group: "shoulders-are-forward",
    code: 4,
    title: "shoulders-are-forward",
    icon: Icons.ShouldersAreForward,
    inflammationIcon: Icons.ShouldersAreForwardOn
  },
  RightTrunkLateralTranslation: {
    group: "upper-back-is-shifted",
    code: 5,
    title: "upper-back-is-shifted-right",
    icon: Icons.UpperBackIsTilted,
    inflammationIcon: Icons.UpperBackIsTiltedOn
  },
  LeftTrunkLateralTranslation: {
    group: "upper-back-is-shifted",
    code: 5,
    title: "upper-back-is-shifted-left",
    icon: Icons.UpperBackIsTilted,
    inflammationIcon: Icons.UpperBackIsTiltedOn
  },
  TrunkExtension: {
    group: "arched-back",
    code: 6,
    title: "arched-back",
    icon: Icons.ArchedBack,
    inflammationIcon: Icons.ArchedBackOn
  },
  RightLateralHipElevation: {
    group: "elevated-hip",
    code: 7,
    title: "elevated-right-hip",
    icon: Icons.ElevatedHip,
    inflammationIcon: Icons.ElevatedHipOn
  },
  LeftLateralHipElevation: {
    group: "elevated-hip",
    code: 7,
    title: "elevated-left-hip",
    icon: Icons.ElevatedHip,
    inflammationIcon: Icons.ElevatedHipOn
  },
  AnteriorPelvicTilt: {
    group: "tilted-pelvis",
    code: 8,
    title: "tilted-pelvis-forwards",
    icon: Icons.TiltedPelvis,
    inflammationIcon: Icons.TiltedPelvisOn
  },
  PosteriorPelvicTilt: {
    group: "tilted-pelvis",
    code: 8,
    title: "tilted-pelvis-backwards",
    icon: Icons.TiltedPelvis,
    inflammationIcon: Icons.TiltedPelvisOn
  },
  RightGenuVarum: {
    group: "right-knee-angle",
    code: 10,
    title: "right-knee-angle-out",
    icon: Icons.KneePositionOut,
    inflammationIcon: Icons.KneePositionOutOn
  },
  RightGenuValgum: {
    group: "right-knee-angle",
    code: 10,
    title: "right-knee-angle-in",
    icon: Icons.KneePositionIn,
    inflammationIcon: Icons.KneePositionInOn
  },
  LeftGenuVarum: {
    group: "left-knee-angle",
    code: 11,
    title: "left-knee-angle-out",
    icon: Icons.KneePositionOut,
    inflammationIcon: Icons.KneePositionOutOn
  },
  LeftGenuValgum: {
    group: "left-knee-angle",
    code: 11,
    title: "left-knee-angle-in",
    icon: Icons.KneePositionIn,
    inflammationIcon: Icons.KneePositionInOn
  },
  KneeExtension: {
    group: "knees-pushed-back",
    code: 12,
    title: "knees-pushed-back",
    icon: Icons.KneesPushedBack,
    inflammationIcon: Icons.KneesPushedBackOn
  },
}

export const deviationGroups: IDictionary<string[]> = {
  "head-tilted": [
    "RightHeadLateralFlexion",
    "LeftHeadLateralFlexion"
  ],
  "head-is-forward": [
    "ForwardHeadProtrusion"
  ],
  "elevate-shoulder": [
    "RightShoulderElevation",
    "LeftShoulderElevation"
  ],
  "shoulders-are-forward": [
    "AnteriorShoulderDeviation"
  ],
  "upper-back-is-shifted": [
    "RightTrunkLateralTranslation",
    "LeftTrunkLateralTranslation"
  ],
  "arched-back": [
    "TrunkExtension"
  ],
  "elevated-hip": [
    "RightLateralHipElevation",
    "LeftLateralHipElevation"
  ],
  "tilted-pelvis": [
    "AnteriorPelvicTilt",
    "PosteriorPelvicTilt"
  ],
  "right-knee-angle": [
    "RightGenuVarum",
    "RightGenuValgum"
  ],
  "left-knee-angle": [
    "LeftGenuVarum",
    "LeftGenuValgum"
  ],
  "knees-pushed-back": [
    "KneeExtension"
  ]
}
