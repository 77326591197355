import React from "react";
import Grid from "@mui/material/Grid";
import ImageMapping from "../ImageMapping/ImageMapping";

export const Photos = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <div className="personal-posture-image col">
          <ImageMapping frontal={true}/>
        </div>
      </Grid>
      <Grid item xs={6}>
        <div className="personal-posture-image">
          <ImageMapping frontal={false}/>
        </div>
      </Grid>
    </Grid>
  );
}