import React, { useEffect } from 'react';
import ScrollToTop from 'react-scroll-up';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { AnalysisState, UserDataState } from '../../states/recoilStates';
import { Photos } from "./components/Photos";
import { NoPhotos } from "./components/NoPhotos";
import AnalysisDetails from "./AnalysisDetails/AnalysisDetails";
import { Container, Grid } from '@mui/material';
import { dataLayerProps } from "../../config/GTMdataLayer";
import TagManager from "react-gtm-module";

import './PostureAnalysis.scss'
import ScrollToTopImg from '../../assets/images/scrollToTop.png';

const NO_SEVERITY_THRESHOLD = 25;

export default function PostureAnalysis() {
  const { t } = useTranslation('PostureAnalysis');
  const userData = useRecoilValue(UserDataState);
  const { deviations } = useRecoilValue(AnalysisState);
  const [sortByScore, setSortByScore] = React.useState(false);
  const background = userData.frontPhoto && userData.sidePhoto ? 'circle-shifted-background' : 'circle-straight-background';
  const score = deviations.some(deviation => deviation.severity > NO_SEVERITY_THRESHOLD);

  useEffect(() => {
    // GTM tags injection
    const tagManagerArgs = {
      dataLayer: dataLayerProps(
        window.location.pathname.toLowerCase(),
        sortByScore ? "sort-by-score" : "sort-head-to-toe"
      )[0],
      dataLayerName: 'dataLayer'
    }
    if (process.env.REACT_APP_AZURE_ENV === 'PROD') {
      TagManager.dataLayer(tagManagerArgs);
    }
  }, [sortByScore]);

  return (
    <div className={`posture-analysis-wrapper ${background}`}>
      <div className="posture-analysis-content">
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={10} md={8} lg={6}>
            <Container>
              {userData.frontPhoto && userData.sidePhoto ? <Photos/> : <NoPhotos text={{
                title: t('no-photos-title'),
                description: t('no-photos-description')
              }}/>}

              <div className="posture-analysis-header">
                <h1>{t('main-title')}</h1>
                <p className="font-16">{t('main-desc1')}<br/>{t('main-desc2')}</p>
              </div>

              <div className="posture-analysis-sorting">
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <h2 onClick={() => setSortByScore(false)}
                        className={sortByScore ? "sort-title" : "sort-title active-link"}>
                      {t('sort-head-to-toe')}
                    </h2>
                  </Grid>
                  <Grid item xs={6}>
                    <h2 onClick={() => setSortByScore(true)}
                        className={sortByScore ? "sort-title active-link" : "sort-title"}>
                      {t('sort-by-score')}
                    </h2>
                  </Grid>
                </Grid>
              </div>

              <div className="posture-analysis-details-wrapper">
                <AnalysisDetails deviations={deviations} byScore={sortByScore}/>
              </div>

              <p>{t('score-calculated-desc')}</p>

              <div className="text-center bottom-stick-button-wrapper">
                <Link to={score ? '/FocusAreas' : '/NoFocusAreas'} className="link-white pc-theme-button orange">
                  {t('button-title')}
                </Link>
              </div>
            </Container>
          </Grid>
        </Grid>
      </div>

      <div className="scroll-to-top-button">
        <ScrollToTop showUnder={160}>
          <img src={ScrollToTopImg} alt="Scroll Top" className="scroll-top-image"/>
        </ScrollToTop>
      </div>
    </div>
  );
}
