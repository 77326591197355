import base64 from 'base-64';
import queryString from 'query-string';
import type { TJebbitData } from "../types/Jebbit.type";

/**
 * Parse base64 Jebbit response to the JebbitData object
 * @param response
 * @constructor
 */
export const parseJebbitResponse = (response: string): TJebbitData => {
    const base64string = response.charAt(0) === '?' ? response.substring(1) : response;
    const jebbitQueryString = base64.decode(base64string);

    return queryString.parse(jebbitQueryString) as unknown as TJebbitData;
}