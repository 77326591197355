import AngledHips from './AngledHips.png';
import AngledHipsOn from './AngledHipsOn.png';
import ArchedBack from './ArchedBack.png';
import ArchedBackOn from './ArchedBackOn.png';
import ElevatedHip from './ElevatedHip.png';
import ElevatedHipOn from './ElevatedHipOn.png';
import ElevatedShoulder from './ElevatedShoulder.png';
import ElevatedShoulderOn from './ElevatedShoulderOn.png';
import ElevatedShoulderRight from './ElevatedShoulderRight.png';
import ElevatedShoulderOnRight from './ElevatedShoulderOnRight.png';
import FootAngleIn from './FootAngleIn.png';
import FootAngleInOn from './FootAngleInOn.png';
import FootAngleOut from './FootAngleOut.png';
import FootAngleOutOn from './FootAngleOutOn.png';
import HeadIsForward from './HeadIsForward.png';
import HeadIsForwardOn from './HeadIsForwardOn.png';
import HeadTilt from './HeadTilt.png';
import HeadTiltOn from './HeadTiltOn.png';
import KneePositionIn from './KneePositionIn.png';
import KneePositionInOn from './KneePositionInOn.png';
import KneePositionOut from './KneePositionOut.png';
import KneePositionOutOn from './KneePositionOutOn.png';
import KneesPushedBack from './KneesPushedBack.png';
import KneesPushedBackOn from './KneesPushedBackOn.png';
import ShouldersAreForward from './ShouldersAreForward.png';
import ShouldersAreForwardOn from './ShouldersAreForwardOn.png';
import TiltedPelvis from './TiltedPelvis.png';
import TiltedPelvisOn from './TiltedPelvisOn.png';
import UpperBackIsTilted from './UpperBackIsTilted.png';
import UpperBackIsTiltedOn from './UpperBackIsTiltedOn.png';

export const Icons = {
    AngledHips,
    AngledHipsOn,
    ArchedBack,
    ArchedBackOn,
    ElevatedHip,
    ElevatedHipOn,
    ElevatedShoulder,
    ElevatedShoulderOn,
    ElevatedShoulderRight,
    ElevatedShoulderOnRight,
    FootAngleIn,
    FootAngleInOn,
    FootAngleOut,
    FootAngleOutOn,
    HeadIsForward,
    HeadIsForwardOn,
    HeadTilt,
    HeadTiltOn,
    KneePositionIn,
    KneePositionInOn,
    KneePositionOut,
    KneePositionOutOn,
    KneesPushedBack,
    KneesPushedBackOn,
    ShouldersAreForward,
    ShouldersAreForwardOn,
    TiltedPelvis,
    TiltedPelvisOn,
    UpperBackIsTilted,
    UpperBackIsTiltedOn
}
