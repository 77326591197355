import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { UserDataState } from '../../states/recoilStates';
import { Container, Grid } from '@mui/material';
import { constants } from "../../config/appConfig";

import './AnalysisSent.scss';
import EmailSentIcon from '../../assets/images/emailSentIcon.png';

export default function AnalysisSent() {
  const { t } = useTranslation('AnalysisSent');
  const { email } = useRecoilValue(UserDataState);

  return (
    <div className="analysis-sent-wrapper">
      <div className="analysis-sent-content text-center circle-straight-background">
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={12} md={6}>
            <Container>
              <img src={EmailSentIcon} alt="Email icon" className="img-fluid email-icon"/>
              <h1>{t('analysis-sent-title')}</h1>
              <p className="font-16">
                <span>{t('email-desc-line1')}</span>
                <br/>
                <span>{t('email-desc-line2')}</span>
                <br/>
                <a>{email}</a>
              </p>
            </Container>
          </Grid>
        </Grid>
      </div>

      <div className="improve-posture-wrapper text-center">
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={12} md={6}>
            <Container>
              <h2>{t('tips-title')}</h2>
              <a className="pc-theme-button"
                 onClick={() => window.open(constants.LEARN_ABOUT_POSTURE_URL)}>
                {t('learn-more-posture-btn')}
              </a>
            </Container>
          </Grid>
        </Grid>
      </div>

      <div className="healthy-workout-wrapper text-center">
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={9} md={9} lg={6} xl={6}>
            <Container>
              <h2>{t('come-back-title')}</h2>
              <p>{t('come-back-desc1')}</p>
              <p>{t('come-back-desc2')}</p>

              <a className="pc-theme-button orange"
                 onClick={() => window.open(constants.HEALTHY_WORKOUT_URL)}>
                {t('visit-workout-button')}
              </a>
            </Container>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
