import * as localForage from "localforage";
import { atom, AtomEffect, DefaultValue, RecoilState } from 'recoil';
import { type TBiotonixAnalysis, DefaultBiotonixAnalysis } from '../types/Biotonix.type';
import { DefaultUserData, IUserData } from '../types/UserData.type';

// eslint-disable-next-line
const localForageEffect: (key: string) => AtomEffect<any> = (key) => ({ setSelf, onSet }) => {
  setSelf(localForage.getItem(key).then(savedValue =>
    savedValue != null
      ? JSON.parse(savedValue as string)
      : new DefaultValue()
  ));

  onSet((newValue, _, isReset) => {
    isReset
      ? localForage.removeItem(key)
      : localForage.setItem(key, JSON.stringify(newValue));
  });
}

/**
 * UserData object
 */
export const UserDataState: RecoilState<IUserData> = atom({
  key: 'UserData',
  default: DefaultUserData,
  effects: [
    localForageEffect('UserData')
  ]
});
/**
 * BiotonixAnalysis object
 */
export const AnalysisState: RecoilState<TBiotonixAnalysis> = atom({
  key: 'analysisState',
  default: DefaultBiotonixAnalysis,
  effects: [
    localForageEffect('analysisState')
  ]
});
/**
 * Photo shooting mode
 */
export const SelfieState = atom({
  key: 'SelfieState',
  default: false,
  effects: [
    localForageEffect('selfieState')
  ]
});
/**
 * Frontal(face) or lateral(side) photo should be taken
 */
export const FaceState = atom({
  key: 'FaceState',
  default: true
});
/**
 * What photos are taken
 */
export const ShootState = atom({
  key: 'ShootState',
  default: {
    frontalShot: false,
    sideShot: false
  }
});
