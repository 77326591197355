import React from "react";
import Grid from "@mui/material/Grid";
import NoImagesIcon from "../../../assets/images/crossRounded.png";

export const NoPhotos = (props: { text: { title: string, description: string } }) => {
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <div className='no-images-wrapper'>
          <img src={NoImagesIcon} alt="No photos"/>
          <h1 className="no-image-wrapper-title">{props.text.title}</h1>
          <p>{props.text.description}</p>
        </div>
      </Grid>
    </Grid>
  );
}