import React from "react";

import "./Footer.scss";
import VoltarenLogo from "../../assets/images/voltaren-logo-de.png";
import PoweredByImage from "../../assets/images/biotonix-powered.png";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Impressum from "../../assets/pdf/HaltungscheckVoltaren_ToU_DE_230921.pdf";

export const FooterDE = () => {
  return (
    <footer className="de-footer-wrapper">
      <div className="de-footer-top">
        <div>
          <img src={VoltarenLogo} className="voltaren-icon" alt="Voltaren Logo"/>
          <div className="partnership text-center">in Partnerschaft mit</div>
          <img src={PoweredByImage} alt="Powered by Biotonix" className="biotonix-icon"/>
        </div>
        <ul>
          <li><a href={Impressum} className="link-white">Nutzungsbedingungen</a></li>
          <li><a href="https://www.voltaren.de/kontakt/" className="link-white">Kontakt</a></li>
          <li><a href="https://www.imprint.haleon.com/de-de/" className="link-white">Impressum</a></li>
          <li><a href="https://www.privacy.haleon.com/de-de/general/" className="link-white"
                 target="_blank">Cookie-Richtlinien</a></li>
        </ul>
      </div>
      <div className="de-footer-bottom">
        © 2023 Haleon oder Lizenzgeber.
        Marken sind Eigentum der Haleon Unternehmensgruppe oder an diese lizenziert.
        GlaxoSmithKline Consumer Healthcare GmbH & Co. KG ist Teil der Haleon Unternehmensgruppe.
        PM-DE-VOLT-23-00219
      </div>
    </footer>
  );
}