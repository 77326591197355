import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

import TagManager from "react-gtm-module";
import { tagManagerArgs } from "./config/GTMconfig";

import "./styles/index.scss";
import "./styles/globalFonts.css";

if (process.env.REACT_APP_AZURE_ENV === "PROD") {
  TagManager.initialize(tagManagerArgs);
}

const root = ReactDOM.createRoot(
  document.getElementById("root")
);

root.render(
  <BrowserRouter>
    <App/>
  </BrowserRouter>
);
