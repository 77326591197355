import { uploadData } from "../../../services/uploadData";
import { checkAnalysis } from "../../../services/checkAnalysis";
import { IUserData } from "../../../types/UserData.type";
import type { TBiotonixAnalysis } from "../../../types/Biotonix.type";
import { SetterOrUpdater } from "recoil";

/**
 * Upload data to backend, get analysis result from Biotonix
 * @param userData
 * @param setUserData
 * @param setAnalysis
 * @constructor
 */
export const GetAnalysis = async (
  userData: IUserData,
  setUserData: SetterOrUpdater<IUserData>,
  setAnalysis: SetterOrUpdater<TBiotonixAnalysis>
) => {
  const response = await uploadData(userData);
  const analysis = await checkAnalysis(response.data.trackId);

  setUserData((prevState: IUserData) => ({ ...prevState, trackId: response.data.trackId }));
  setAnalysis(analysis.data);
}