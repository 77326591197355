import React from 'react';
import { Spinner } from "../../components/Spinner/Spinner";

import './LoadingScreen.scss';

export const LoadingScreen = () => {
  return (<>
    <div className="loading-screen-wrapper">
      <Spinner/>
    </div>
  </>);
}
