import { v4 as uuidv4 } from 'uuid';
import AreasComponent from "./AreasComponent";
import { useRecoilValue } from "recoil";
import { AnalysisState } from "../../../states/recoilStates";
import { getSliderClassName } from "../../../helpers/getSliderClassName";
import { deviationDictionary } from "../../PostureAnalysis/AnalysisDetails/AnalysisDetailsDictionary";
import { useTranslation } from "react-i18next";
import Images from '../../../assets/images/FocusReport';

type DeviationType = {
  tag: string;
  severity: number;
}

const SEVERITY_THRESHOLD = 25;
const RESULTS_NUMBER = 3;

export default function Areas() {
  const { t } = useTranslation('FocusAreas');
  const analysis = useRecoilValue(AnalysisState);
  const severeDeviations = analysis.deviations.filter((deviation: DeviationType) =>
    deviation.tag in deviationDictionary && deviation.severity >= SEVERITY_THRESHOLD);

  severeDeviations.sort((a: { severity: number; }, b: { severity: number; }) =>
    a.severity < b.severity ? 1 : -1);

  return <>
    {severeDeviations.slice(0, RESULTS_NUMBER).map((deviation: DeviationType) =>
      <AreasComponent key={uuidv4()}
                      name={t(`${deviation.tag}.name`)}
                      severity={deviation.severity}
                      image={Images[deviation.tag]}
                      sliderClassName={getSliderClassName(deviation.severity)}
                      postureTips={t(`${deviation.tag}.postureTips`, { returnObjects: true })}
                      movementTips={t(`${deviation.tag}.movementTips`, { returnObjects: true })}
                      exercisePlaylist={t(`${deviation.tag}.exercisePlaylist`, { returnObjects: true })}/>)}
  </>;
}