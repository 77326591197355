import axios from 'axios';
import { IUserData } from "../types/UserData.type";
import { APP_LNG, FAKES, X_FUNCTIONS_KEY } from "../config/appConfig";

const UPLOAD_DATA_URL = process.env.REACT_APP_UPLOAD_DATA_URL || FAKES.UPLOAD_DATA_URL;
/**
 * Upload user data to backend
 * @param userData
 */
export const uploadData = async (userData: IUserData) => await axios.post(
  UPLOAD_DATA_URL, {
    frontImage: userData.frontPhoto,
    sideImage: userData.sidePhoto,
    firstName: userData.first_name ?? '',
    lastName: userData.last_name ?? '',
    email: userData.email ?? '',
    ageGroup: userData.age ?? '',
    consent: true,
    reportStatus: false,
    errorCode: 0,
    dayActivity: userData.time_seated ?? '',
    height: userData.height ?? 0,
    weight: userData.weight ?? 0,
    gender: userData.gender ?? '',
    marketCode: APP_LNG
  }, { headers: { 'x-functions-key': X_FUNCTIONS_KEY } });
