import promiseRetry from 'promise-retry';
import axios from 'axios';
import { FAKES, X_FUNCTIONS_KEY } from "../config/appConfig";

const GET_ANALYSIS_URL = process.env.REACT_APP_GET_ANALYSIS_URL || FAKES.GET_ANALYSIS_URL;
/**
 * Check backend for Biotonix analysis
 * @param trackId
 * @constructor
 */
export const checkAnalysis = async (trackId: string) => {
  const instance = axios.create({
    validateStatus: (status) => status == 200
  });

  return await promiseRetry((retry) => {
    return instance
      .post(GET_ANALYSIS_URL,
        { trackId: trackId },
        { headers: { 'x-functions-key': X_FUNCTIONS_KEY } })
      .catch((err) => {
        if (err.response.status == 204) {
          console.info('Waiting for analysis ...');
          retry(err);
        }

        if (err.response.status == 417) {
          console.info(err.response.data)
        }

        throw err;
      });
  });
}
