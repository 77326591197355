import axios from "axios";
import { APP_LNG, FAKES, X_FUNCTIONS_KEY } from "../config/appConfig";

const EMAIL_REPORT_URL = process.env.REACT_APP_EMAIL_REPORT_URL || FAKES.EMAIL_REPORT_URL;
/**
 * Email Posture Report to user
 * @param trackId
 * @param email
 * @constructor
 */
export const emailReport = async (trackId: string, email: string) => {
  return await axios.post(EMAIL_REPORT_URL, {
    trackId: trackId,
    email: email,
    marketCode: APP_LNG
  }, { headers: { 'x-functions-key': X_FUNCTIONS_KEY } });
}