import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import DevMenu from '../DevMenu/DevMenu';
import { APP_LNG, HiddenBackButtonPages } from "../../config/appConfig";
import {Container, Grid} from '@mui/material';

import './Header.scss';
import Logo from '../../assets/images/posture-coach-logo-1.png';
import LogoGerman from '../../assets/images/posture-coach-logo-german.png';
import BackArrow from '../../assets/images/back-arrow.svg';

/**
 * Set the header logo based on the market
 */
const headerLogo = APP_LNG === 'DE' ? LogoGerman : Logo;
export default function Header() {
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const hiddenButton = HiddenBackButtonPages.includes(pathname.toLowerCase()) ? 'hidden' : '';

  return (
      <div className="header-container">
        <Container>
          <Grid container>
            <Grid item xs={2}>
              <div>
                <button onClick={() => navigate(-1)} className={`back-button-wrapper ${hiddenButton}`}>
                  <img src={BackArrow} alt="Back arrow" className="header-action-icons"/>
                </button>
              </div>
            </Grid>

            <Grid item xs={8}>
              <div className="logo-wrapper">
                <img src={headerLogo} alt="Voltaren logo" className="logo-content"/>
              </div>
            </Grid>

            {process.env.NODE_ENV === 'development' && <Grid item xs={2}>
              <div className='logo-wrapper'>
                <DevMenu></DevMenu>
              </div>
            </Grid>}
          </Grid>
        </Container>
      </div>
  );
}
