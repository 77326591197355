import * as React from 'react';
import { NavigateFunction } from "react-router";
import FileSaver from 'file-saver';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { checkReport } from "../../../services/checkReport";
import { Spinner } from "../../../components/Spinner/Spinner";
import { PDFReportFilename } from "../../../config/appConfig";

export const DownloadReportButton = (props: { trackId: string }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('FocusAreas');
  const [reportButton, setReportButton] = React.useState(null);

  React.useEffect(() => {
    checkReport(props.trackId)
      .then(response => {
        setReportButton(<DownloadReport blob={response.data.blob}
                                        text={t('download-report')}
                                        reportFilename={PDFReportFilename}
                                        navigate={navigate}/>);
      })
      .catch(error => {
        if (error.response && error.response.status) {
          switch (error.response.status) {
            case 204:
              setReportButton(<ReportGenerating text={t('report-generating')}/>);
              break;
            case 401:
            case 404:
            case 406:
              setReportButton(<StartNewJourney text={t('start-new-journey')}/>);
              break;
            default:
              setReportButton(null);
          }
        }
      });
  }, []);

  return reportButton ?? <ReportGenerating text={t('report-generating')}/>;
}

const DownloadReport = (props: {
  blob: { type: string; data: ArrayBuffer };
  text: string;
  reportFilename: string;
  navigate: NavigateFunction
}) =>
  <Link to="" className="link-white pc-theme-button orange" onClick={() => {
    FileSaver.saveAs(new Blob([new Uint8Array(props.blob.data).buffer]), props.reportFilename);
    timeout().then(() => props.navigate('/AnalysisDownloaded'))
  }}>
    {props.text}
  </Link>;
const ReportGenerating = (props: { text: string }) =>
  <Link to="" className="flex-space-around link-white pc-theme-button grey">
    <Spinner color={'white'} scale={0.2}/>
    <div>{props.text}</div>
  </Link>;
const StartNewJourney = (props: { text: string }) =>
  <Link to="/" className="link-white pc-theme-button orange">
    {props.text}
  </Link>;
const timeout = () => {
  return new Promise(res => setTimeout(res, 500));
}