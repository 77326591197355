import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './NetworkError.scss';
import NetworkErrorImage from '../../assets/images/networkError.png';

export default function NetworkError() {
  const { t } = useTranslation('NetworkError');

  return (
    <div className="network-error-wrapper">
      <div className="network-error-container">
        <img src={NetworkErrorImage} alt="Network Error"/>
        <h1>{t('title')}</h1>
        <p>{t('description')}</p>

        <Link to={'/'} className="pc-theme-button">OK</Link>
      </div>
    </div>
  );
}
