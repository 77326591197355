import React from 'react';
import Webcam from 'react-webcam';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ErrorPopup } from '../../components/ErrorPopup/ErrorPopup';
import { FaceState, SelfieState, ShootState, UserDataState } from '../../states/recoilStates';
import { Button, Container } from '@mui/material';

import './CameraCapture.scss'
import FrontGridImage from '../../assets/images/frontGrid.svg';
import SideGridImage from '../../assets/images/sideGrid.png';
import CameraButtonImage from '../../assets/images/cameraButton2.png';

const FACING_MODE_USER = 'user';
const FACING_MODE_ENVIRONMENT = 'environment';
const COUNTDOWN_VALUE = 10;

export default function CameraCapture() {
  const navigate = useNavigate();
  const { t } = useTranslation('CameraCapture');
  const webcamRef = React.useRef(null);
  const selfie = useRecoilValue(SelfieState);
  const [, setUserData] = useRecoilState(UserDataState);
  const [face, setFace] = useRecoilState(FaceState);
  const [shoot, setShoot] = useRecoilState(ShootState);
  const [timer, setTimer] = React.useState(COUNTDOWN_VALUE);
  const [showGridText, setShowGridText] = React.useState(true);
  const [timerCountdown, setTimerCountdown] = React.useState(false);
  const [showCameraButton, setShowCameraButton] = React.useState(true);
  const tick: { current: NodeJS.Timeout | null } = React.useRef(null);
  const [errorMessage] = React.useState('');
  const videoConstraints: MediaTrackConstraints = {
    facingMode: selfie ? FACING_MODE_USER : FACING_MODE_ENVIRONMENT,
    width: 4096,
    height: 4096
  }

  // Countdown
  const startCountdown = () => {
    clearInterval(tick.current);

    tick.current = setInterval(() => setTimer(count => count - 1), 1000);
  }

  // Take a photo
  const takePhoto = () => {
    if (selfie) {
      setShowGridText(false);
      setTimerCountdown(true);
      setShowCameraButton(false);
      startCountdown();
    } else {
      setTimer(0);
    }
  }

  // Capture and analysis
  const capture = React.useCallback(() => {
    const screenshot = webcamRef.current.getScreenshot({ width: 1200, height: 1600 });

    setUserData(prevState => face
      ? ({ ...prevState, frontPhoto: screenshot })
      : ({ ...prevState, sidePhoto: screenshot }));
  }, [webcamRef]);

  React.useEffect(() => {
    if (!timer) {
      clearInterval(tick.current);
      capture();
      setShoot(prevState => {
        return {
          frontalShot: true,
          sideShot: prevState.frontalShot
        }
      });
    }

    if (selfie && !face) {
      takePhoto();
    }
  }, [timer]);

  // Finalization and redirects
  React.useEffect(() => {
    if (face && shoot.frontalShot && !shoot.sideShot) {
      setFace(false);

      navigate('/TurnSideways');
    }

    if (shoot.frontalShot && shoot.sideShot) {
      navigate('/AnalyzingResults');
    }
  }, [shoot.frontalShot, shoot.sideShot]);

  return (
    <Container className="camera-capture-wrapper">
      <div className={`camera-capture-video-frame ${selfie && 'flip'}`}>
        <Webcam ref={webcamRef}
                width={1200}
                height={1600}
                imageSmoothing={true}
                screenshotFormat="image/jpeg"
                forceScreenshotSourceSize={false}
                videoConstraints={videoConstraints}></Webcam>
        {/*<div className="clip-mask-item"></div>*/}
      </div>

      <div className="camera-capture-captions">
        <div className="centered-child">
          <img src={face ? FrontGridImage : SideGridImage} alt="Silhouette pattern"
               className={`silhouette-image ${selfie && 'flip'}`}/>
        </div>
        <div className="centered-child text-center">
          {showGridText && <p className="silhouette-instruction">{t('silhouette-title')}</p>}
        </div>
        <div className="centered-child">
          {timerCountdown && <div className="timer-counter">{timer}</div>}
        </div>

        {showCameraButton && <CameraButton action={takePhoto}/>}
      </div>

      {errorMessage && <ErrorPopup message={errorMessage}/>}
    </Container>
  );
}

const CameraButton = (props: { action: () => void }) =>
  <div className="camera-capture-button-wrapper">
    <Button onClick={() => props.action()}>
      <img src={CameraButtonImage} alt="Camera capture button" className="camera-capture-button"/>
    </Button>
  </div>;
