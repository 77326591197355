import { APP_LNG } from "../../config/appConfig";
import { FooterDE } from "./FooterDE";
import { FooterEN } from "./FooterEN";

export const Footer = () => {
  switch (APP_LNG) {
    case 'DE':
      return <FooterDE/>
    default:
      return <FooterEN/>
  }
}