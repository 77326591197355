import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './NotFound.scss';

export default function NotFound() {
  const { t } = useTranslation('NotFound');

  return (
    <div className="not-found-wrapper">
      <div className="not-found-container">
        <h1>404</h1>
        <p>{t('description')}</p>

        <Link to={'/'} className="pc-theme-button link-white">{t('get-back-btn')}</Link>
      </div>
    </div>
  );
}
