import React from 'react';

import './Spinner.scss';

export const Spinner = (props: { color: string, scale: number }) => {
    return <div className={`lds-spinner ${props.color}`} style={{ transform: `scale(${ props.scale })` }}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>;
}

Spinner.defaultProps = {
    color: 'orange',
    scale: 1
}