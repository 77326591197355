import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GetAnalysis } from "./GetAnalysis";
import { useRecoilState, useSetRecoilState } from 'recoil';
import { AnalysisState, UserDataState } from '../../../states/recoilStates';
import { ErrorPopup } from '../../../components/ErrorPopup/ErrorPopup';

import './AnalyzingResults.scss';
import LoaderImage from '../../../assets/images/loader.gif';

export default function AnalyzingResults() {
  const navigate = useNavigate();
  const { t } = useTranslation('CameraCapture');
  const setAnalysis = useSetRecoilState(AnalysisState);
  const [userData, setUserData] = useRecoilState(UserDataState);
  const [errorMessage, setErrorMessage] = React.useState(null);

  React.useEffect(() => {
    GetAnalysis(userData, setUserData, setAnalysis)
      .then(() => navigate('/PostureAnalysis'))
      .catch(error => setErrorMessage(error));
  }, []);

  return (<>
    <div className="analyzing-results-wrapper">
      <div className="analyzing-results-content text-center">
        <p>{t('loader-text1')}<br/>{t('loader-text2')}</p>
        <img src={LoaderImage} alt="loader"/>
      </div>
    </div>

    {errorMessage && <ErrorPopup message={errorMessage}/>}
  </>);
}
