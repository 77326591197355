import axios from "axios";
import { constants } from "../config/appConfig";

/**
 * Get market region from headers
 * @returns {Promise<string>}
 * @constructor
 */
export const getMarketRegion = async (): Promise<string> => {
  try {
    const response = await axios.head(window.location.href || "/");

    return response.headers.marketregion || constants.DEFAULT_MARKET_REGION;
  } catch (e) {
    console.warn('Default market region is used', e);

    return constants.DEFAULT_MARKET_REGION;
  }
}
