import type { TBiotonixAnalysis } from "../../../types/Biotonix.type";

export const GetLandmarks = (analysis: TBiotonixAnalysis, dot: string) => {
  const landmarks = analysis.frontLandmarks.concat(analysis.sideLandmarks);
  const dotIndex = landmarks.findIndex(element => element.tag === dot);

  return dotIndex >= 0 ? { x: landmarks[dotIndex].x, y: landmarks[dotIndex].y } : { x: 0, y: 0 }
}

export const EyeCenterDetection = ['FA02'];
export const ShoulderDetection = ['FA05', 'FA06', 'FA07'];
export const HipsDetection = ['FA09', 'FA11'];
export const BottomLegDetection = ['FA16', 'FA18'];
export const LeftLegDetection = ['FA09', 'FA14', 'FA16', 'FA19'];
export const RightLegDetection = ['FA11', 'FA15', 'FA18', 'FA20'];
export const LeftHandDetection = ['FA05', 'FA12'];
export const RightHandDetection = ['FA07', 'FA13'];
export const FaceLineDetection = ['FA02', 'FA04', 'FA06', 'FA08'];
export const SideFaceDetection = ['SD01', 'SD02', 'SD03', 'SD04'];
export const SideHipsDetection = ['SD05', 'SD08'];
export const SideLegDetection = ['SD09', 'SD10'];
export const SideCenterLineDetection = ['SD04', 'SD09'];
