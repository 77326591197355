import "./recoil";
import "./config/appConfig";
import './i18n';

import { Suspense, useEffect, useState } from 'react';
import { Navigate } from "react-router";
import { Route, Routes, useLocation } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { dataLayerPush } from "./config/GTMdataLayer";
import { MetaData } from "./components/MetaData/MetaData";
import { BackButtonRedirects, HiddenHeaderPages } from "./config/appConfig";

import Header from './components/Header/Header';
import HomePage from './pages/HomePage/HomePage';
import Jebbit from "./pages/Jebbit/Jebbit";
import ScanInstructions from './pages/ScanInstructions/ScanInstructions';
import CameraCapture from './pages/CameraCapture/CameraCapture';
import PostureAnalysis from './pages/PostureAnalysis/PostureAnalysis';
import FocusAreas from './pages/FocusAreas/FocusAreas';
import NoFocusAreas from './pages/FocusAreas/NoFocusAreas';
import AnalysisSent from './pages/AnalysisSent/AnalysisSent';
import AnalysisDownloaded from "./pages/AnalysisDownloaded/AnalysisDownloaded";
import NetworkError from './pages/NetworkError/NetworkError';
import TurnSideways from "./pages/CameraCapture/TurnSideways/TurnSideways";
import AnalyzingResults from "./pages/CameraCapture/AnalyzingResults/AnalyzingResults";
import { LoadingScreen } from "./pages/LoadingScreen/LoadingScreen";
import NotFound from "./pages/NotFound/NotFound";

export default function App() {
  const { pathname, search } = useLocation();
  const [showHeader, setShowHeader] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (pathname.toLowerCase() in BackButtonRedirects) {
      history.pushState('', '', BackButtonRedirects[pathname.toLowerCase()])
      history.pushState('', '', pathname.concat(search));
    }

    if (process.env.REACT_APP_AZURE_ENV === 'PROD') {
      dataLayerPush(pathname, search);
    }

    setShowHeader(!HiddenHeaderPages.includes(pathname.toLowerCase()));
  }, [pathname]);

  return (
    <>
      <MetaData/>
      <div className="App main-content-wrapper">
        <RecoilRoot>
          <Suspense fallback={<LoadingScreen/>}>
            {showHeader && <Header/>}
            <Routes>
              <Route path="/" element={<HomePage/>}/>
              <Route path="Jebbit" element={<Jebbit/>}/>
              <Route path="ScanInstructions" element={<ScanInstructions/>}/>
              <Route path="CameraCapture" element={<CameraCapture/>}/>
              <Route path="TurnSideways" element={<TurnSideways/>}/>
              <Route path="AnalyzingResults" element={<AnalyzingResults/>}/>
              <Route path="PostureAnalysis" element={<PostureAnalysis/>}/>
              <Route path="FocusAreas" element={<FocusAreas/>}/>
              <Route path="NoFocusAreas" element={<NoFocusAreas/>}/>
              <Route path="AnalysisSent" element={<AnalysisSent/>}/>
              <Route path="AnalysisDownloaded" element={<AnalysisDownloaded/>}/>

              <Route path="LoadingScreen" element={<LoadingScreen/>}/>
              <Route path="NetworkError" element={<NetworkError/>}/>
              <Route path="NotFound" element={<NotFound/>}/>
              <Route path="*" element={<Navigate replace to="/NotFound"/>}/>
            </Routes>
          </Suspense>
        </RecoilRoot>
      </div>
    </>
  );
}
