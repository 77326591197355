import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AnalysisDetailsComponent } from "./AnalysisDetailsComponent";
import { deviationDictionary, deviationGroups } from './AnalysisDetailsDictionary';
import { type TBiotonixAnalysis, DefaultBiotonixAnalysis } from "../../../types/Biotonix.type";

import './AnalysisDetailsComponent.scss';

type AnalysisDetailsProps = {
  deviations: TBiotonixAnalysis['deviations'];
  byScore: boolean;
}

export default function AnalysisDetails(props: AnalysisDetailsProps) {
  const details = [];

  for (const [groupName, groupKeys] of Object.entries(deviationGroups)) {
    const deviation = props.deviations.find(deviationResult => groupKeys.includes(deviationResult.tag) && deviationResult.severity > 0);
    const title = deviation ? deviationDictionary[deviation.tag].title : groupName;
    const value = deviation ? deviation.severity : 0;
    const icon = deviation && deviation.severity >= 25 ? deviationDictionary[deviation.tag].inflammationIcon : deviationDictionary[groupKeys[0]].icon;

    details.push(<AnalysisDetailsComponent key={uuidv4()} title={title} value={value} icon={icon}/>);
  }

  if (props.byScore) {
    details.sort((a, b) => b.props.value - a.props.value);
  }

  return <>{details.map(element => element)}</>
}

AnalysisDetails.defaultProps = {
  deviations: DefaultBiotonixAnalysis['deviations'],
  byScore: false
}
