import { GTMEvent } from "./GTMconfig";
import { parseJebbitResponse } from "../helpers/parseJebbitResponse";
import TagManager from "react-gtm-module";

type TDataLayer = {
  event: string,
  eventCategory: string,
  eventAction: string,
  eventLabel?: string,
}[];

/**
 * Get dataLayer props
 * @param pathname
 * @param label
 */
export const dataLayerProps = (pathname: string, label?: string): TDataLayer => {
  const eventCategory = GTMEvent[pathname]?.category;
  const eventAction = GTMEvent[pathname]?.action;
  const dataLayer: TDataLayer = [dataLayerObject(eventCategory, eventAction, label ?? GTMEvent[pathname]?.label)];

  if (pathname === "/scaninstructions") {
    const jebbitData = parseJebbitResponse(label) as { [key: string]: string };
    const insights: TDataLayer = Object.keys(jebbitData).map(key =>
      dataLayerObject("Consumer Insights", key, jebbitData[key])
    );

    delete dataLayer[0]?.eventLabel;

    return dataLayer.concat(insights);
  }

  return dataLayer;
}

/**
 * Get dataLayer object
 * @param eventCategory
 * @param eventAction
 * @param eventLabel
 */
const dataLayerObject = (eventCategory: string, eventAction: string, eventLabel?: string) => {
  return Object.assign({
      "event": "customEvent",
      "eventCategory": eventCategory,
      "eventAction": eventAction,
    },
    eventLabel && { eventLabel: eventLabel }
  );
}
export const dataLayerPush = (pathname: string, search: string) => {
  dataLayerProps(pathname.toLowerCase(), search.slice(1)).forEach(dataLayer => {
    TagManager.dataLayer({
      dataLayer: dataLayer,
      dataLayerName: "dataLayer"
    })
  })
}

export const isOneTrustLoaded = (array: { [key: string]: string }[]) => {
  return array.find((el: { [key: string]: string }) => el.event === "OneTrustLoaded") !== undefined;
}