import { APP_LNG } from "../../config/appConfig";

const JEBBIT_URL = process.env[`REACT_APP_JEBBIT_${APP_LNG}_URL`] || "";

export default function Jebbit() {
  return (
    <div style={{ height: "100vh" }}>
      <iframe className="jebbit-iframe"
              src={JEBBIT_URL}
              allowFullScreen={true}
              style={{ width: "100%", minHeight: "600px", height: "100%", border: "none" }}
              onLoad={() => {
                function embedJebbit(t: string) {
                  function e(t: string) {
                    const e = "attach" === t ? window.addEventListener : window.removeEventListener;
                    e("DOMContentLoaded", n, !1),
                      e("load", n, !1),
                      e("scroll", n, !1),
                      e("resize", n, !1)
                  }

                  const i: HTMLIFrameElement = document.querySelector(t);

                  function n() {
                    let t, e, n, o;
                    e = (t = i).getBoundingClientRect(), n = t.clientHeight / 2, o = t.clientWidth / 2,
                    e.top >= 0
                    && e.left >= 0
                    && e.top <= (window.innerHeight || document.documentElement.clientHeight) - n
                    && e.left <= (window.innerWidth || document.documentElement.clientWidth) - o
                    && i.contentWindow.postMessage("startJebbitCampaign", "*")
                  }

                  window.addEventListener("message", function t(i) {
                    i && "https://voltaren.jebbit.com" === i.origin && ("jebbitSessionCreated" === i.data
                      ? e("remove") : "jebbitCampaignLoaded" === i.data
                        ? n() : i.data && "redirect" === i.data.action
                        && i.data.options && i.data.options.link
                        && (window.location.href = i.data.options.link))
                  }, !1), e("attach")
                }

                embedJebbit(".jebbit-iframe")
              }}>
      </iframe>

      <script>
        {`window.addEventListener("message", function(e) {
          if (e.data.event && (e.data.event.match(/customevent/ig))) {
            window.dataLayer.push(e.data)
          }
        }, false);`}
      </script>
    </div>
  );
}