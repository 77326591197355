import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import { parseJebbitResponse } from "../../helpers/parseJebbitResponse";
import { FaceState, SelfieState, ShootState, UserDataState } from "../../states/recoilStates";
import { Container, Grid } from "@mui/material";
import { APP_LNG } from "../../config/appConfig";

import "./ScanInstructions.scss";
import InstructionPhoto1 from "../../assets/images/ScanInstructions/instructions-photo-1.png";
import InstructionPhoto2 from "../../assets/images/ScanInstructions/instructions-photo-2.png";
import InstructionPhoto3en from "../../assets/images/ScanInstructions/instructions-photo-3.png";
import InstructionPhoto3de from "../../assets/images/ScanInstructions/instructions-photo-3-de-2-meters.png";
import { Checkbox } from "rc-checkbox";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import pdf from "../../assets/pdf/data-protection-de.pdf";

/**
 * Use different image for German version
 */
const InstructionPhoto3 = APP_LNG === 'DE' ? InstructionPhoto3de : InstructionPhoto3en;
/**
 * Show the Privacy Notes annotation for DE market
 */
const usePrivacy = APP_LNG === 'DE';

export default function ScanInstructions() {
  const { search } = useLocation();
  const { t } = useTranslation('ScanInstructions');

  const [privacyCheck, setPrivacyCheck] = useState<boolean>(!usePrivacy);
  const setSelfieState = useSetRecoilState(SelfieState);
  const [, setUserData] = useRecoilState(UserDataState);
  const resetShootState = useResetRecoilState(ShootState);
  const resetFrontalPoseState = useResetRecoilState(FaceState);

  React.useEffect(() => {
    if (search) {
      setUserData(prevState => ({ ...prevState, ...parseJebbitResponse(search) }));
    }

    resetShootState();
    resetFrontalPoseState();
  }, []);

  return (
    <div className="scan-instructions-wrapper">
      <div className="scan-instructions-content">
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={10} md={8} lg={6}>
            <Container>
              <section>
                <img src={InstructionPhoto1} alt="Correct environment"/>
                <h2>{t('env-title-1')}</h2>
                <h3>{t('env-title-2')}</h3>
                <p>{t('env-desc')}</p>
              </section>
              <h3>{t('options')}</h3>
              <section>
                <img src={InstructionPhoto2} alt="Scan with a friend"/>
                <h2>{t('friend-title-1')}</h2>
                <h3>{t('friend-title-2')}</h3>
                <p>{t('friend-desc')}</p>
              </section>
              <section>
                <img src={InstructionPhoto3} alt="Self-scan"/>
                <h2>{t('self-scan-title-1')}</h2>
                <h3>{t('self-scan-title-2')}</h3>
                <p>{t('self-scan-desc')}</p>
              </section>

              {usePrivacy && <div className="privacy-notes-de">
                <Checkbox onChange={(e) => setPrivacyCheck(e.target.checked)}/>
                <div className="privacy-notes-annotation">
                  {t('privacy-notes-annotation')}
                  &nbsp;
                  <a href={pdf}>{t('privacy-notes-link')}</a>
                  &nbsp;
                  {t('privacy-notes-annotation-2')}
                </div>
              </div>}

              <div className="buttons-container text-center">
                <Link
                  to="/CameraCapture?scan-with-friend"
                  onClick={() => setSelfieState(false)}
                  className={`link-white pc-theme-button orange ${!privacyCheck && "disabled-link"}`}>
                  {t('scan-with-friend-btn')}
                </Link>
                <br/>
                <Link to="/CameraCapture?self-scan"
                      onClick={() => setSelfieState(true)}
                      className={`link-white pc-theme-button ${!privacyCheck && "disabled-link"}`}>
                  {t('self-scan-btn')}
                </Link>
              </div>
            </Container>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
