import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import defaultImage from "../../assets/images/startpage-posture-bg.png";

export const MetaData = () => {
  const { t } = useTranslation('metadata');

  return (
    <Helmet>
      <title>{t('title')}</title>
      <meta name="description" content={t('description')}/>

      <meta itemProp="name" content={t('title')}/>
      <meta itemProp="description" content={t('description')}/>
      <meta itemProp="image" content={defaultImage}/>

      <meta property="og:url" content={t('homepage')}/>
      <meta property="og:type" content="website"/>
      <meta property="og:title" content={t('title')}/>
      <meta property="og:description" content={t('description')}/>
      <meta property="og:image" content={defaultImage}/>

      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:title" content={t('title')}/>
      <meta name="twitter:description" content={t('description')}/>
      <meta name="twitter:image" content={defaultImage}/>
    </Helmet>
  );
}