import AnteriorPelvicTilt from './AnteriorPelvicTilt.png';
import AnteriorShoulderDeviation from './AnteriorShoulderDeviation.png';
import ForwardHeadProtrusion from './ForwardHeadProtrusion.png';
import KneeExtension from './KneeExtension.png';
import LeftGenuValgum from './LeftGenuValgum.png';
import LeftGenuVarum from './LeftGenuVarum.png';
import LeftHeadLateralFlexion from './LeftHeadLateralFlexion.png';
import LeftLateralHipElevation from './LeftLateralHipElevation.png';
import LeftShoulderElevation from './LeftShoulderElevation.png';
import LeftTrunkLateralTranslation from './LeftTrunkLateralTranslation.png';
import PosteriorPelvicTilt from './PosteriorPelvicTilt.png';
import RightGenuValgum from './RightGenuValgum.png';
import RightGenuVarum from './RightGenuVarum.png';
import RightHeadLateralFlexion from './RightHeadLateralFlexion.png';
import RightLateralHipElevation from './RightLateralHipElevation.png';
import RightShoulderElevation from './RightShoulderElevation.png';
import RightTrunkLateralTranslation from './RightTrunkLateralTranslation.png';
import TrunkExtension from './TrunkExtension.png';

interface IDictionary<TValue> {
    [id: string]: TValue;
}
export default {
    AnteriorPelvicTilt,
    AnteriorShoulderDeviation,
    ForwardHeadProtrusion,
    KneeExtension,
    LeftGenuValgum,
    LeftGenuVarum,
    LeftHeadLateralFlexion,
    LeftLateralHipElevation,
    LeftShoulderElevation,
    LeftTrunkLateralTranslation,
    PosteriorPelvicTilt,
    RightGenuValgum,
    RightGenuVarum,
    RightHeadLateralFlexion,
    RightLateralHipElevation,
    RightShoulderElevation,
    RightTrunkLateralTranslation,
    TrunkExtension,
} as IDictionary<string>