import React from 'react';
import * as Landmarks from "./GetLandmarks";
import { useRecoilValue } from "recoil";
import { AnalysisState, UserDataState } from "../../../states/recoilStates";

const LineWidth = 6;
const StrokeStyle = '#FF671F';

export default function ImageMapping(props: { frontal: boolean }) {
  const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
  const userData = useRecoilValue(UserDataState);
  const analysis = useRecoilValue(AnalysisState);
  const [image, setImage] = React.useState(null);
  const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 });

  React.useEffect(() => {
    if (canvasRef.current) {
      const canvasImage = new Image();

      canvasImage.src = props.frontal ? userData.frontPhoto : userData.sidePhoto;
      canvasImage.onload = () => {
        const ctx = canvasRef.current.getContext('2d');
        const scanTypes = props.frontal ? FrontLandmarks : SideLandmarks;

        ctx.drawImage(canvasImage, 0, 0, canvasImage.width, canvasImage.height);
        ctx.lineWidth = LineWidth;
        ctx.strokeStyle = StrokeStyle;

        for (const type of scanTypes) {
          ctx.beginPath();
          type.forEach(dot =>
            ctx.arc(
              Landmarks.GetLandmarks(analysis, dot).x,
              Landmarks.GetLandmarks(analysis, dot).y,
              2,
              0,
              2 * Math.PI,
              true
            )
          );
          ctx.stroke();
        }

        setImage(canvasRef.current.toDataURL());
        setDimensions({ width: canvasImage.width, height: canvasImage.height });
      }
    }
  }, [image]);

  return (
    <div className="image-mapping-wrapper">
      <div className="canvas-area-wrapper">
        <canvas ref={canvasRef} width={dimensions.width} height={dimensions.height}></canvas>
      </div>
      <div>
        <img src={image} className="display-image" alt="Analyzed image" width={dimensions.width}/>
      </div>
    </div>
  );
}

const FrontLandmarks = [
  Landmarks.EyeCenterDetection,
  Landmarks.FaceLineDetection,
  Landmarks.ShoulderDetection,
  Landmarks.HipsDetection,
  Landmarks.BottomLegDetection,
  Landmarks.LeftHandDetection,
  Landmarks.RightHandDetection,
  Landmarks.LeftLegDetection,
  Landmarks.RightLegDetection
];
const SideLandmarks = [
  Landmarks.SideFaceDetection,
  Landmarks.SideHipsDetection,
  Landmarks.SideLegDetection,
  Landmarks.SideCenterLineDetection
];
