import React from "react";
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from "react-i18next";
import Carousel from 'react-multi-carousel';
import { Slider } from '@mui/material';

import 'react-multi-carousel/lib/styles.css';
import './AreasComponent.scss';

type AreasComponentProps = {
  name: string;
  severity: number;
  image: string;
  sliderClassName: string;
  postureTips: string[];
  movementTips: string[];
  exercisePlaylist: { title: string, url: string }[];
}

export default function AreasComponent(props: AreasComponentProps) {
  const { t } = useTranslation('FocusAreas');

  return (
    <div className="areas-component-wrapper">
      <h1 className="areas-component-title">{props.name}</h1>
      <p className="areas-component-irregularity-percent">{props.severity} % {t('deviation')}</p>

      <div className="areas-component-image">
        <img src={props.image} alt={props.image}/>
      </div>

      <div className="sliderRedColor">
        <Slider disabled defaultValue={props.severity} aria-label="Disabled slider"/>
      </div>

      <div className="tips-content">
        <h5 className="tips-content-title">
          {props.postureTips.length > 1 ? t('tips-for-posture') : t('tip-for-posture')}:
        </h5>
        <ul className="tips-content-desc">
          {props.postureTips.map(tip => <li key={uuidv4()}>{tip}</li>)}
        </ul>
      </div>

      <div className="tips-content">
        <h5 className="tips-content-title">
          {props.movementTips.length > 1 ? t('tips-for-posture') : t('tip-for-posture')}:
        </h5>
        <ul className="tips-content-desc">
          {props.movementTips.map(tip => <li key={uuidv4()}>{tip}</li>)}
        </ul>
      </div>

      <div className="tips-content">
        <h5 className="tips-content-title">{t('exercises')}:</h5>
      </div>

      <div className="exercises-video-wrapper">
        <Carousel responsive={responsive} showDots={true} arrows={false} partialVisible={true} minimumTouchDrag={25}>
          {props.exercisePlaylist.map(exercise => <div key={uuidv4()} className="carousel-item">
            <div className='video-card-wrapper'>
              <iframe width="100%"
                      src={exercise.url}
                      title={exercise.title}
                      allow="accelerometer; encrypted-media; gyroscope; picture-in-picture;"
                      allowFullScreen></iframe>
              <h3 className='video-title'>{exercise.title}</h3>
            </div>
          </div>)}
        </Carousel>
      </div>
    </div>
  );
}

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 }, items: 1, partialVisibilityGutter: 120
  }, desktop: {
    breakpoint: { max: 3000, min: 1024 }, items: 1, partialVisibilityGutter: 120
  }, tablet: {
    breakpoint: { max: 1024, min: 464 }, items: 1, partialVisibilityGutter: 80
  }, mobile: {
    breakpoint: { max: 464, min: 0 }, items: 1, partialVisibilityGutter: 40
  }
};
